<template>
  <span class="top">
    <NotificationBar
      v-for="mynotification in notifications"
      :key="mynotification.id"
      :notification="mynotification"
      :class="isPhone ? 'top_notification_small' : 'top_notification'"
    />
  </span>
</template>

<script>
import NotificationBar from './NotificationBar'
import { mapState } from 'vuex'
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  components: { NotificationBar },
  computed: mapState('notification', ['notifications'])
}
</script>

<style>
.top_notification {
position: fixed !important;
  top: 30px;
  width: 500px;
  left: 50%;
  z-index: 99 !important;
  transform: translateX(-50%);
}


.top_notification_small {
  position: fixed;
  top: 52px;
  height: 100px;
  width: 99%;
  left: 50%;
  z-index: 99 !important;
  transform: translateX(-50%);
}
</style>
