<template>
  <v-alert
    border="right"
    v-model="alert"
    :color="getColor()"
    :type="alertType"
    class=" pa-2 d-flex flex-column charcoalTile"
    dark
    transition="scale-transition"
  >
    <v-divider vertical color="" class="progressActive px-2" />
    <span
      v-html="notification.message"
      :class="textSizeXSmall + ' paper--text'"
    >
    </span>
    <v-spacer />
    <v-card-actions class="pa-0">
      <v-spacer />
      <v-btn large color="paper" plain @click="dismiss()">
        Close
      </v-btn>
    </v-card-actions>
  </v-alert>
</template>
<script>
import { mapActions } from 'vuex'
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      timeout: null,
      alertType: 'error',
      alert: true
    }
  },
  mounted() {
    if (
      this.notification.type == 'info' ||
      this.notification.type == 'success'
    ) {
      this.alertType = 'info'
      let expireIn = 1000
      if (this.notification.timeout > 0) expireIn = this.notification.timeout
      this.timeout = setTimeout(() => this.remove(this.notification), expireIn)
    } else {
      this.alertType = 'error'
      this.timeout = -1
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  },
  methods: {
    dismiss() {
      this.alert = false
      this.$store.dispatch('notification/clear')
    },
    getColor() {
      if (this.notification.type == 'error') return 'accent'
      else if (this.notification.type == 'info') return 'gold darken-1'
      else return 'charcoal lighten-1'
    },
    ...mapActions('notification', ['remove'])
  }
}
</script>
